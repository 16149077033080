<template>
  <div>
    <b-modal title="마스터 상세" size="xl" v-model="modal.detail" v-if="item"  ok-title="저장" cancel-title="닫기" @hide="hideModal">
      <template v-slot:modal-title>
        <div v-if="item.id">
          {{item.name}}
          <b-badge class="pointer" variant="success" @click="copyText(item.id)">{{item.id}}<i class="fa fa-copy ml-1"></i></b-badge>
        </div>
        <div v-else>
          <strong>신규 마스터 등록</strong>
        </div>
      </template>

      <b-row>
        <b-col cols="12" lg="6" class="mb-3">
          <strong>브랜드</strong><span class="ml-1 text-danger">*</span>
          <v-select :disabled="!$R('SKU_INHOUSE')" v-model="item.brand" :options="brand" placeholder="브랜드를 선택해주세요."></v-select>
        </b-col>
        <b-col cols="12" lg="6" class="mb-3">
          <strong>카테고리</strong><span class="ml-1 text-danger">*</span>
          <i class="ml-1 fa fa-question-circle" v-b-tooltip="'끝의 * 표시는 최종카테고리를, → 표시는 하위 카테고리가 있음을 의미합니다)'"></i>
          <br/>
          <b-input-group>
            <b-form-input :value="lastCategory && categoryMap[lastCategory].path || ''" readonly></b-form-input>
            <b-input-group-append>
              <b-dropdown variant="success" text="변경" right>
                <b-dropdown-form class="py-2" :style="{width: !$S.breakpoint.isLteXs ? '650px' : '380px'}">
                  <b-row>
                    <b-col class="mb-2">
                      <small>1차 카테고리</small>
                      <b-form-radio-group class="mt-1" :disabled="!$R('SKU_INHOUSE')" v-model="inputCate[0]" :options="category.filter(e => e.category.length === 3)"
                                          @change="selectCategory" stacked></b-form-radio-group>
                    </b-col>
                    <b-col class="mb-2">
                      <small>2차 카테고리</small>
                      <b-form-radio-group class="mt-1" :disabled="!$R('SKU_INHOUSE')" v-model="inputCate[1]"
                                          :options="item.category.length > 0 ? cate[1] : cateNoGender" @change="selectCategory" stacked></b-form-radio-group>
                    </b-col>
                    <b-col class="mb-2">
                      <small>3차 카테고리</small>
                      <b-form-radio-group class="mt-1" :disabled="!$R('SKU_INHOUSE')" v-model="inputCate[2]" :options="cate[2]" @change="selectCategory"
                                          stacked></b-form-radio-group>
                    </b-col>
                    <b-col class="mb-2">
                      <small>4차 카테고리</small>
                      <b-form-radio-group class="mt-1" :disabled="!$R('SKU_INHOUSE')" v-model="inputCate[3]" :options="cate[3]" @change="selectCategory"
                                          stacked></b-form-radio-group>
                    </b-col>
                  </b-row>
                </b-dropdown-form>
              </b-dropdown>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <div class="mb-3">
        <strong>디자이너 SKU</strong><span class="ml-1 text-danger">*</span><br/>
        <b-input-group>
          <b-input :disabled="!$R('SKU_INHOUSE')" class="" v-model="item.designer_sku" placeholder="디자이너 SKU를 입력해주세요"></b-input>
            <template v-if="item.designer_sku">
              <b-input-group-append>
                <b-btn variant="light" @click="$utils.copyAlert(item.designer_sku, {msg: `${item.designer_sku} 로 복사되었습니다.`})">
                  <i class="fa fa-copy"></i>
                </b-btn>
              </b-input-group-append>
              <b-input-group-append>
                <b-btn variant="success"
                       @click="$utils.copyAlert(`https://search.shopping.naver.com/search/all?query=${encodeURIComponent(item.designer_sku)}`,
                        {msg: `https://search.shopping.naver.com/search/all?query=${encodeURIComponent(item.designer_sku)} 로 복사되었습니다.`})">
                  N<span class="d-none d-lg-inline-block">링크</span><i class="fa fa-copy ml-1"></i>
                </b-btn>
              </b-input-group-append>
              <b-input-group-append>
                <b-btn variant="warning"
                       @click="$utils.copyAlert(`https://search.danawa.com/dsearch.php?module=goods&act=dispMain&k1=${encodeURIComponent(item.designer_sku)}`,
                      {msg: `https://search.danawa.com/dsearch.php?module=goods&act=dispMain&k1=${encodeURIComponent(item.designer_sku)} 로 복사되었습니다.`})">
                  D<span class="d-none d-lg-inline-block">링크</span><i class="fa fa-copy ml-1"></i>
                </b-btn>
              </b-input-group-append>
              <b-input-group-append>
                <b-btn variant="info"
                       @click="$utils.copyAlert(`https://www.google.co.kr/search?q=${encodeURIComponent(item.designer_sku)}&tbm=shop`,
                        {msg: `https://www.google.co.kr/search?q=${encodeURIComponent(item.designer_sku)}&tbm=shop 로 복사되었습니다.`})">
                  G<span class="d-none d-lg-inline-block">링크</span><i class="fa fa-copy ml-1"></i>
                </b-btn>
              </b-input-group-append>
            </template>
          </b-input-group>

        <span v-if="designerSkuSamples.length">
          <small class="text-danger font-weight-bold">예시: {{ designerSkuSamples.join(' / ') }}</small><br/>
        </span>
      </div>

      <b-row>
        <b-col cols="12" lg="6" class="mb-3">
          <strong>마스터 상품명 (한글)</strong><span class="ml-1 text-danger">*</span><br/>
          <b-input-group>
            <b-input :disabled="!$R('SKU_INHOUSE')" class="" v-model="item.name" placeholder="마스터 상품명을 입력해주세요"></b-input>
            <b-input-group-append v-if="item.name">
              <b-btn variant="light" @click="$utils.copyAlert(item.name, {msg: `${item.name} 로 복사되었습니다.`})">
                <i class="fa fa-copy"></i>
              </b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="3" lg="6" class="mb-3">
          <strong>컬러</strong><span class="ml-1 text-danger">*</span><br/>
          <color-radio v-model="item.color"></color-radio>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" lg="6" class="mb-3">
          <strong>마스터 상품명 (영문)</strong><br/>
          <b-input-group>
            <b-input :disabled="!$R('SKU_INHOUSE')" class="" v-model="item.name_en" placeholder="마스터 상품명을 입력해주세요"></b-input>
            <b-input-group-append v-if="item.name_en">
              <b-btn variant="light" @click="$utils.copyAlert(item.name_en, {msg: `${item.name_en} 로 복사되었습니다.`})">
                <i class="fa fa-copy"></i>
              </b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-col>
        <b-col cols="3" lg="6" class="mb-3">
          <strong>시즌</strong>
          <b-form inline>
            <b-input type="number" v-mask="'##'" :state="warning.season" class="text-center w-70px mr-2 form-control"
                     :disabled="!$R('SKU_INHOUSE')||item.seasonCate === 'EMPTY'" autocomplete="off" v-model="item.seasonNumber" placeholder="숫자"></b-input>
            <b-form-radio-group class="mt-1" id="season" name="season" :disabled="!$R('SKU_INHOUSE')" v-model="item.seasonCate" :options="[
              {text: 'FW', value: 'FW'},
              {text: 'SS', value: 'SS'},
              {text: '시즌없음', value: 'EMPTY'},
            ]"></b-form-radio-group>
          </b-form>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" lg="6" class="mb-3">
          <strong>소스 URL</strong><span class="ml-1 text-danger">*</span>
          <b-input-group>
            <b-input :disabled="!$R('SKU_INHOUSE')" class="" v-model="item.src_url" placeholder="마스터 검증에 활용한 링크의 URL을 입력해 주세요"></b-input>
            <b-input-group-append v-if="item.src_url">
              <b-btn variant="light" @click="$utils.copyAlert(item.src_url, {msg: `${item.src_url} 로 복사되었습니다.`})">
                <i class="fa fa-copy"></i>
              </b-btn>
            </b-input-group-append>
          </b-input-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <strong>임시 이미지</strong><span class="ml-1 text-danger">*</span>
          <div class="d-flex flex-row flex-wrap" @drop.prevent.stop="(e) => handleTempFile(e)" @dragover="handleDragover" @dragenter="handleDragover">
            <draggable class="d-flex flex-row flex-wrap" v-model="item.temp_images" @start="drag.image=true" @end="drag.image=false">
              <template v-for="(img, idx) in item.temp_images">
                <div class="text-primary d-flex align-items-center justify-content-center position-relative mr-3 mb-3"
                     style="width:120px; height:120px; border-radius: 6px; border: solid 1px #ced4da; background-image: url(/img/transparent_bg.png)">
                  <a :href="'https://i.balaan.io/' + img.square_webp" target="_blank"><img :src="'https://i.balaan.io/' + img.square_webp" style="max-width:118px; max-height:118px;" /></a>
<!--                  <b-button class="position-absolute" style="top:5px; right:35px;" variant="primary" size="sm" @click="copyPicTag(img)"><i class="fa fa-copy"></i></b-button>-->
                  <b-button v-if="$R('SKU_INHOUSE')" class="position-absolute" style="top:5px; right:5px;" variant="danger" size="sm" @click="item.temp_images.splice(idx, 1)"><i class="fa fa-close"></i></b-button>
                </div>
              </template>
            </draggable>
            <div v-if="$R('SKU_INHOUSE')" class="pointer text-secondary d-flex align-items-center justify-content-center mr-3 mb-3" style="width:120px; height:120px; border-radius: 6px; border: dashed 1px #5b73e8;" @click="_=>{$refs.temp_file.value = null;$refs.temp_file.click()}">
              <i class="fa fa-plus fa-5x" v-if="!busy.uploadTemp"></i>
              <b-spinner v-if="busy.uploadTemp" style="width: 4rem; height: 4rem;"></b-spinner>
            </div>
          </div>
          <input type="file" ref="temp_file" style="display: none" @change="(e) => handleTempFile(e)" multiple :disabled="busy.uploadTemp">
        </b-col>
        <b-col cols="6">
          <strong>최종 이미지</strong>
          <div class="d-flex flex-row flex-wrap" @drop.prevent.stop="(e) => handleFile(e)" @dragover="handleDragover" @dragenter="handleDragover">
            <draggable class="d-flex flex-row flex-wrap" v-model="item.images" @start="drag.image=true" @end="drag.image=false">
              <template v-for="(img, idx) in item.images">
                <div class="text-primary d-flex align-items-center justify-content-center position-relative mr-3 mb-3"
                     style="width:120px; height:120px; border-radius: 6px; border: solid 1px #ced4da; background-image: url(/img/transparent_bg.png)">
                  <a :href="'https://i.balaan.io/' + img.square_webp" target="_blank"><img :src="'https://i.balaan.io/' + img.square_webp" style="max-width:118px; max-height:118px;" /></a>
                  <b-button v-if="(/^...002/.test(item.category) && idx === 1) || (!/^...002/.test(item.category) && idx === 0)" class="position-absolute" style="top:5px; left:5px; cursor:unset;" variant="primary" size="sm">EP</b-button>
                  <b-button class="position-absolute" style="top:5px; right:5px;" variant="danger" size="sm" @click="item.images.splice(idx, 1)"><i class="fa fa-close"></i></b-button>
                </div>
              </template>
            </draggable>
            <div class="pointer text-secondary d-flex align-items-center justify-content-center mr-3 mb-3" style="width:120px; height:120px; border-radius: 6px; border: dashed 1px #5b73e8;" @click="_=>{$refs.file.value = null;$refs.file.click()}">
              <i class="fa fa-plus fa-5x" v-if="!busy.upload"></i>
              <b-spinner v-if="busy.upload" style="width: 4rem; height: 4rem;"></b-spinner>
            </div>
          </div>
          <input type="file" ref="file" style="display: none" @change="(e) => handleFile(e)" multiple :disabled="busy.upload">
        </b-col>
      </b-row>

      <div>
        <strong>촬영 이미지</strong>
        <div class="d-flex flex-row flex-wrap" @drop.prevent.stop="(e) => handleStudioFile(e)" @dragover="handleDragover" @dragenter="handleDragover">
          <draggable class="d-flex flex-row flex-wrap" v-model="item.studio_images" @start="drag.image=true" @end="drag.image=false">
            <template v-for="(img, idx) in item.studio_images">
              <div class="text-primary d-flex align-items-center justify-content-center position-relative mr-3 mb-3"
                   style="width:120px; height:120px; border-radius: 6px; border: solid 1px #ced4da; background-image: url(/img/transparent_bg.png)">
                <a :href="'https://i.balaan.io/' + img.square_webp" target="_blank"><img :src="'https://i.balaan.io/' + img.square_webp" style="max-width:118px; max-height:118px;" /></a>
                <!--                  <b-button class="position-absolute" style="top:5px; right:35px;" variant="primary" size="sm" @click="copyPicTag(img)"><i class="fa fa-copy"></i></b-button>-->
                <b-button v-if="$R('SKU_INHOUSE')" class="position-absolute" style="top:5px; right:5px;" variant="danger" size="sm" @click="item.studio_images.splice(idx, 1)"><i class="fa fa-close"></i></b-button>
              </div>
            </template>
          </draggable>
          <div v-if="$R('SKU_INHOUSE')" class="pointer text-secondary d-flex align-items-center justify-content-center mr-3 mb-3" style="width:120px; height:120px; border-radius: 6px; border: dashed 1px #5b73e8;" @click="_=>{$refs.studio_file.value = null;$refs.studio_file.click()}">
            <i class="fa fa-plus fa-5x" v-if="!busy.uploadStudio"></i>
            <b-spinner v-if="busy.uploadStudio" style="width: 4rem; height: 4rem;"></b-spinner>
          </div>
        </div>
        <input type="file" ref="studio_file" style="display: none" @change="(e) => handleStudioFile(e)" multiple :disabled="busy.uploadStudio">
      </div>

      <!-- 좌우 영역으로 정보 구분 -->
      <b-row>
        <b-col cols="12" lg="6" class="mb-2">
          <!-- 추가정보 파트 -->
          <b-tabs>
            <b-tab title="추가정보">
              <div class="mb-3">
                <strong>등록상태</strong>
                <b-form-radio-group class="col-form-label mr-3" size="sm" :disabled="!$R('SKU_INHOUSE')" v-model="item.processing_status" :options="[
                  {text: '검수 전', value: 'processing'},
                  {text: '검수 후', value: 'registered', disabled: !item.id}
                ]"></b-form-radio-group>
                <b-input v-if="item.id" :disabled="!$R('SKU_INHOUSE')" autocomplete="off" placeholder="변경사유를 입력해주세요" v-model="item.processing_status_reason"></b-input>
              </div>

              <div class="mb-3">
                <strong>노출상태</strong>
                <b-form-radio-group class="col-form-label mr-3" size="sm" :disabled="!$R('SKU_INHOUSE')" v-model="item.display_status" :options="[
                  {text: '미노출', value: 'notview'},
                  {text: '노출', value: 'view', disabled: !item.id}
                ]"></b-form-radio-group>
                <b-input v-if="item.id" :disabled="!$R('SKU_INHOUSE')" autocomplete="off" placeholder="변경사유를 입력해주세요" v-model="item.display_status_reason"></b-input>
              </div>

              <div class="mb-3">
                <strong>소재 태그</strong>
                <v-multiselect v-model="item.compoSelect" :options="compositionTag" group-values="compositions" group-label="group" track-by="name" label="name"></v-multiselect>
              </div>

              <div class="mb-3">
                <strong>소재</strong>
                <b-input id="composition" :disabled="!$R('SKU_INHOUSE')" autocomplete="off" placeholder="영문 Single line text" v-model="item.composition"></b-input>
              </div>

              <div class="mb-3">
                <strong>상품 특징</strong>
                <v-select v-model="item.feature1" :options="features"></v-select>
                <v-select v-model="item.feature2" :options="features"></v-select>
                <v-select v-model="item.feature3" :options="features"></v-select>
              </div>

              <div class="mb-3">
                <strong>사이즈 변환(사이즈 조견표)</strong>
                <b-form-textarea :rows="5" :disabled="!$R('SKU_INHOUSE')" v-model="item.size_table"
                                 :placeholder="'영문 Multi line text\nex) - 40FR (7UK/10US/40EU/IT)'"></b-form-textarea>
              </div>

              <div class="mb-3">
                <strong>상세 정보</strong>
                <b-form-textarea :rows="2" :disabled="!$R('SKU_INHOUSE')" v-model="item.description" placeholder="상세 정보를 입력해주세요."></b-form-textarea>
              </div>

              <div class="mb-3">
                <strong>실측 사이즈</strong>
                <v-select :disabled="!$R('SKU_INHOUSE')" v-model="item.catalog_template" :options="catalogTemplate" placeholder="템플릿을 선택해주세요."></v-select>
                <b-row class="mt-2">
                  <b-col class="text-center" cols="4">
                    <img v-if="item.catalog_template && item.catalog_template.exists_img !== false" style="max-height:120px;"
                         :src="`https://i.balaan.io/img/catalog/template/${item.catalog_template.code}.jpg`">
                    <div v-else class="mt-5">템플릿 이미지</div>
                  </b-col>
                  <b-col class="pt-0" cols="8">
                    <b-form-textarea id="measurements" :rows="2" :disabled="!$R('SKU_INHOUSE')" v-model="item.measurements"
                                     :placeholder="`실측 사이즈 입력 예시\n총장 : 72cm, 소매 길이 : 42cm, 가슴 : 50cm`" style="min-height: 120px;"></b-form-textarea>
                  </b-col>
                </b-row>
              </div>

              <div class="mb-3">
                <strong>코멘트</strong>
                <b-form-textarea rows="2" :disabled="!$R('SKU_INHOUSE')" v-model="item.comments" placeholder="작업자용 코멘트를 입력해주세요."></b-form-textarea>
              </div>

              <template v-if="item.id">
                <b-row class="">
                  <b-col cols="6" xl="3" class="mb-2">
                    <strong>생성자</strong><br/>
                    <small>{{ _cname }}</small>
                  </b-col>
                  <b-col cols="6" xl="3" class="mb-2">
                    <strong>생성시각</strong><br/>
                    <small>{{ _cdt }}</small>
                  </b-col>
                  <b-col cols="6" xl="3" class="mb-2">
                    <strong>이미지 작업자</strong><br/>
                    <small>{{ item.img_uploader_name || '-' }}</small>
                  </b-col>
                  <b-col cols="6" xl="3" class="mb-2">
                    <strong>이미지 작업시각</strong><br/>
                    <small>{{ item.img_uploaded_dt || '-' }}</small>
                  </b-col>
                  <b-col cols="6" xl="3" class="mb-2">
                    <strong>검수자</strong><br/>
                    <small>{{ item.registrant_name || '-' }}</small>
                  </b-col>
                  <b-col cols="6" xl="3" class="mb-2">
                    <strong>검수시각</strong><br/>
                    <small>{{ item.registered_dt || '-' }}</small>
                  </b-col>
                  <b-col cols="6" xl="3" class="mb-2">
                    <strong>노출자</strong><br/>
                    <small>{{ item.displayer_name || '-' }}</small>
                  </b-col>
                  <b-col cols="6" xl="3" class="mb-2">
                    <strong>노출시각</strong><br/>
                    <small>{{ item.displayed_dt || '-' }}</small>
                  </b-col>
                </b-row>
              </template>
            </b-tab>
          </b-tabs>
        </b-col>
        <b-col cols="12" lg="6" class="mb-2">
          <b-tabs>
            <!-- 최저가 파트 -->
            <b-tab title="발란 추천가">
              <div class="mb-3">
                <div class="mb-1">
                  <strong>API 호출 키워드</strong>
                </div>
                <table class="w-100 mt-1">
                  <tr>
                    <td class="text-right pr-2" style="width:100px">
                    <template>
                      -
                    </template>
                    </td>
                    <td class="">
                      <b-input-group>
                        <b-input v-model="item.naverKeyword" placeholder="키워드를 입력해주세요"></b-input>
                        <b-input-group-append v-if="item.naverKeyword">
                          <b-btn variant="light" @click="$utils.copyAlert(item.naverKeyword, {msg: `${item.naverKeyword} 로 복사되었습니다.`})">
                            <i class="fa fa-copy"></i>
                          </b-btn>
                        </b-input-group-append>
                        <b-input-group-append>
                          <b-button variant="danger" @click="item.naverKeyword = ''"><i class="fa fa-trash"></i></b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </td>
                  </tr>
                </table>
              </div>
              <div class="mb-3">
                <div class="mb-1">
                  <strong>외부최저가(네이버 랭킹)</strong>
                </div>
                <table class="w-100 mt-1" v-if="item.naver_rank_urls">
                  <tr v-for="([url, match], idx) in item.naver_rank_urls.map(e => [e, e.match(/catalog\/(\d+)\??/)])" :key="idx">
                    <td class="text-right pr-2" style="width:100px">
                      <template v-if="match && item.naverRankCatalogMap[match[1]]">
                        <div class="mb-n1">{{ $utils.comma(item.naverRankCatalogMap[match[1]].minPrice) }} 원</div>
                        <small class="text-secondary" :title="item.naverRankCatalogMap[match[1]].dt">
                          {{ item.naverRankCatalogMap[match[1]].date === $utils.kstD() ?
                            item.naverRankCatalogMap[match[1]].dt.substring(11, 19) : item.naverRankCatalogMap[match[1]].date }}
                        </small>
                      </template>
                      <template v-else>
                        -
                      </template>
                    </td>
                    <td class="">
                      <b-input-group>
                        <b-input-group-prepend class="d-md-block d-lg-none d-xl-block w-50px" v-if="match && item.naverRankCatalogMap[match[1]]" title="해당 주소의 상품수">
                          <b-input-group-text class="px-2 justify-content-end">
                            {{item.naverRankCatalogMap[match[1]].productCount}} 개
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-input v-model="item.naver_rank_urls[idx]" placeholder="URL을 입력해주세요"></b-input>
                        <b-input-group-append v-if="url">
                          <b-btn variant="light" @click="$utils.copyAlert(url, {msg: `${url} 로 복사되었습니다.`})">
                            <i class="fa fa-copy"></i>
                          </b-btn>
                        </b-input-group-append>
                        <b-input-group-append>
                          <b-button variant="danger" @click="removeOption('naver_rank_urls', idx)"><i class="fa fa-trash"></i></b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="mb-3">
                <div class="mb-1">
                  <strong>외부최저가(네이버 랭킹 - 해외)</strong>
                </div>
                <table class="w-100 mt-1" v-if="item.naver_rank_abroad_urls">
                  <tr v-for="([url, match], idx) in item.naver_rank_abroad_urls.map(e => [e, e.match(/catalog\/(\d+)\??/)])" :key="idx">
                    <td class="text-right pr-2" style="width:100px">
                      <template v-if="match && item.naverRankCatalogMap[match[1]]">
                        <div class="mb-n1">{{ $utils.comma(item.naverRankCatalogMap[match[1]].minPrice) }} 원</div>
                        <small class="text-secondary" :title="item.naverRankCatalogMap[match[1]].dt">
                          {{ item.naverRankCatalogMap[match[1]].date === $utils.kstD() ?
                            item.naverRankCatalogMap[match[1]].dt.substring(11, 19) : item.naverRankCatalogMap[match[1]].date }}
                        </small>
                      </template>
                      <template v-else>
                        -
                      </template>
                    </td>
                    <td class="">
                      <b-input-group>
                        <b-input-group-prepend class="d-md-block d-lg-none d-xl-block w-50px" v-if="match && item.naverRankCatalogMap[match[1]]" title="해당 주소의 상품수">
                          <b-input-group-text class="px-2 justify-content-end">
                            {{item.naverRankCatalogMap[match[1]].productCount}} 개
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-input v-model="item.naver_rank_abroad_urls[idx]" placeholder="URL을 입력해주세요"></b-input>
                        <b-input-group-append v-if="url">
                          <b-btn variant="light" @click="$utils.copyAlert(url, {msg: `${url} 로 복사되었습니다.`})">
                            <i class="fa fa-copy"></i>
                          </b-btn>
                        </b-input-group-append>
                        <b-input-group-append>
                          <b-button variant="danger" @click="removeOption('naver_rank_abroad_urls', idx)"><i class="fa fa-trash"></i></b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="mb-3">
                <div class="mb-1">
                  <strong>외부최저가(네이버)</strong>
                  <b-badge v-if="item.naver_urls && item.naver_urls.length !== 5" class="ml-1 pointer" variant="success" @click="addOption('naver_urls')">
                    URL 추가하기
                  </b-badge>
                </div>
                <table class="w-100 mt-1" v-if="item.naver_urls">
                  <tr v-for="([url, match], idx) in item.naver_urls.map(e => [e, e.match(/catalog\/(\d+)\??/)])" :key="idx">
                    <!--                    <td class="w-80px d-md-block d-lg-none d-xl-block">
                                          <div class="w-80px">
                                            <sparkline :values="[1200000, 1200000, 1200000, 1100000, 1250000, 1100000, 1250000, 1300000]" variant="info" :width="80" :height="30" />
                                          </div>
                                        </td>-->
                    <td class="text-right pr-2" style="width:100px">
                      <template v-if="match && item.naverCatalogMap[match[1]]">
                        <div class="mb-n1">{{ $utils.comma(item.naverCatalogMap[match[1]].minPrice) }} 원</div>
                        <small class="text-secondary" :title="item.naverCatalogMap[match[1]].dt">
                          {{ item.naverCatalogMap[match[1]].date === $utils.kstD() ?
                            item.naverCatalogMap[match[1]].dt.substring(11, 19) : item.naverCatalogMap[match[1]].date }}
                        </small>
                      </template>
                      <template v-else>
                        -
                      </template>
                    </td>
                    <td class="">
                      <b-input-group>
                        <b-input-group-prepend class="d-md-block d-lg-none d-xl-block w-50px" v-if="match && item.naverCatalogMap[match[1]]" title="해당 주소의 상품수">
                          <b-input-group-text class="px-2 justify-content-end">
                            {{item.naverCatalogMap[match[1]].productCount}} 개
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-input v-model="item.naver_urls[idx]" placeholder="URL을 입력해주세요"></b-input>
                        <b-input-group-append v-if="url">
                          <b-btn variant="light" @click="$utils.copyAlert(url, {msg: `${url} 로 복사되었습니다.`})">
                            <i class="fa fa-copy"></i>
                          </b-btn>
                        </b-input-group-append>
                        <b-input-group-append>
                          <b-button variant="danger" @click="removeOption('naver_urls', idx)"><i class="fa fa-trash"></i></b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="mb-3">
                <div class="mb-1">
                  <strong>외부최저가(다나와)</strong>
                </div>
                <table class="w-100" v-if="item.danawa_urls">
                  <tr v-for="([url, match], idx) in item.danawa_urls.map(e => [e, e.match(/pcode\=(\d+)\??/)])" :key="idx">
                    <td class="text-right pr-2" style="width:100px">
                      <template v-if="match && item.danawaCatalogMap[match[1]]">
                        <div class="mb-n1">{{ $utils.comma(item.danawaCatalogMap[match[1]].minPrice) }} 원</div>
                        <small class="text-secondary" :title="item.danawaCatalogMap[match[1]].dt">
                          {{ item.danawaCatalogMap[match[1]].date === $utils.kstD() ?
                            item.danawaCatalogMap[match[1]].dt.substring(11, 19) : item.danawaCatalogMap[match[1]].date }}
                        </small>
                      </template>
                      <template v-else>
                        -
                      </template>
                    </td>
                    <td class="">
                      <b-input-group>
                        <b-input-group-prepend class="d-md-block d-lg-none d-xl-block w-50px" v-if="match && item.danawaCatalogMap[match[1]]" title="해당 주소의 상품수">
                          <b-input-group-text class="px-2 justify-content-end">
                            {{item.danawaCatalogMap[match[1]].productCount}} 개
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-input v-model="item.danawa_urls[idx]" placeholder="URL을 입력해주세요"></b-input>
                        <b-input-group-append v-if="url">
                          <b-btn variant="light" @click="$utils.copyAlert(url, {msg: `${url} 로 복사되었습니다.`})">
                            <i class="fa fa-copy"></i>
                          </b-btn>
                        </b-input-group-append>
                        <b-input-group-append>
                          <b-button variant="danger" @click="removeOption('danawa_urls', idx)"><i class="fa fa-trash"></i></b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="mb-3">
                <div class="mb-1">
                  <strong>외부최저가(다나와 통합검색)</strong>
                </div>
                <table class="w-100" v-if="item.danawa_list_urls">
                  <tr v-for="([url, match], idx) in item.danawa_list_urls.map(e => [e, e.match(/query\=([a-zA-Z0-9\s\%]+)/)])" :key="idx">
                    <td class="text-right pr-2" style="width:100px">
                      <template v-if="match && item.danawaListMap[decodeURIComponent(match[1])]">
                        <div class="mb-n1">{{ $utils.comma(item.danawaListMap[decodeURIComponent(match[1])].minPrice) }} 원</div>
                        <small class="text-secondary" :title="item.danawaListMap[decodeURIComponent(match[1])].dt">
                          {{ item.danawaListMap[decodeURIComponent(match[1])].date === $utils.kstD() ?
                            item.danawaListMap[decodeURIComponent(match[1])].dt.substring(11, 19) : item.danawaListMap[decodeURIComponent(match[1])].date }}</small>
                      </template>
                      <template v-else>
                        -
                      </template>
                    </td>
                    <td class="">
                      <b-input-group>
                        <b-input-group-prepend class="d-md-block d-lg-none d-xl-block w-50px" v-if="match && item.danawaListMap[decodeURIComponent(match[1])]" title="해당 주소의 상품수">
                          <b-input-group-text class="px-2 justify-content-end">
                            {{item.danawaListMap[decodeURIComponent(match[1])].productCount}} 개
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <b-input v-model="item.danawa_list_urls[idx]" placeholder="URL을 입력해주세요"></b-input>
                        <b-input-group-append v-if="url">
                          <b-btn variant="light" @click="$utils.copyAlert(url, {msg: `${url} 로 복사되었습니다.`})">
                            <i class="fa fa-copy"></i>
                          </b-btn>
                        </b-input-group-append>
                        <b-input-group-append>
                          <b-button variant="danger" @click="removeOption('danawa_list_urls', idx)"><i class="fa fa-trash"></i></b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </td>
                  </tr>
                </table>
              </div>

              <div class="mb-3">
                <div class="mb-1">
                  <strong>수동 최저가</strong>
                </div>
                <table class="w-100 mt-1">
                  <tr>
                    <td class="text-right pr-2" style="width:100px">
                    <template>
                      -
                    </template>
                    </td>
                    <td class="">
                      <b-input-group>
                        <b-input v-model="item.minPriceManual" placeholder="수동최저가를 입력해주세요 (예: 100000)" type="number"></b-input>
                        <b-input-group-append v-if="item.minPriceManual">
                          <b-btn variant="light" @click="$utils.copyAlert(item.minPriceManual, {msg: `${item.minPriceManual} 로 복사되었습니다.`})">
                            <i class="fa fa-copy"></i>
                          </b-btn>
                        </b-input-group-append>
                        <b-input-group-append>
                          <b-button variant="danger" @click="item.minPriceManual = ''"><i class="fa fa-trash"></i></b-button>
                        </b-input-group-append>
                      </b-input-group>
                    </td>
                  </tr>
                </table>
              </div>

              <b-row class="">
                <b-col cols="6" xl="4" class="mb-2">
                  <strong>B 최저가</strong>
                  <i class="ml-1 fa fa-question-circle" v-b-tooltip="`B class 1위인 상품의 발란회원가(Confimed Price)입니다. 검수 후 노출 상태 카탈로그에만 구성됩니다.`"></i>
                  <br/>
                  <div class="text-right">
                    <span :class="item.balaanMinPriceClass">
                      {{ item.minPriceBalaan ? $utils.comma(item.minPriceBalaan) + ' 원' : '-' }}
                    </span>
                    <br/>
                    <small>{{ item.minPriceBalaanDt || '-' }}</small>
                  </div>
                </b-col>
                <!--                <b-col cols="6" xl="4" class="mb-2">-->
                <!--                  <strong>B 최대혜택가</strong>-->
                <!--                  <i class="ml-1 fa fa-question-circle" v-b-tooltip="`B 최저가에 쿠폰할인(${priceMetaMap.global_coupon_rate} %)이 적용된 가격입니다. 추천가 달성시 녹색, 미달성시 붉은색, 추천가가 없으면 검은색 입니다.`"></i>-->
                <!--                  <br/>-->
                <!--                  <div class="text-right">-->
                <!--                    <span :class="item.balaanMinPriceClass">-->
                <!--                      {{ item.minPriceBalaanCoupon ? $utils.comma(item.minPriceBalaanCoupon) + ' 원' : '-' }}-->
                <!--                    </span>-->
                <!--                    <br/>-->
                <!--                    <small>{{ item.minPriceBalaanDt || '-' }}</small>-->
                <!--                  </div>-->
                <!--                </b-col>-->
                <!--                <b-col cols="6" xl="4" class="mb-2">-->
                <!--                  <strong style="letter-spacing: -0.1px">14일 최저판매가</strong><br/>-->
                <!--                  <div class="text-right">-->
                <!--                    {{ item.minSalePrice14d ? $utils.comma(item.minSalePrice14d) + ' 원' : '-' }}<br/>-->
                <!--                    <small>{{ item.minSalePriceDate || '-' }}</small>-->
                <!--                  </div>-->
                <!--                </b-col>-->
                <b-col cols="6" xl="4" class="mb-2">
                  <strong>발란 추천가</strong><br/>
                  <div class="text-right">
                    {{ item.minPriceAll ? $utils.comma(item.minPriceAll) + ' 원' : '-' }}<br/>
                    <small>{{ item.minPriceAllDt || '-'}}</small>
                  </div>
                </b-col>
              </b-row>

              <!--              <b-row>-->
              <!--                <b-col cols="6" xl="4" class="mb-2">-->
              <!--                  <strong>발란 추천가</strong><br/>-->
              <!--                  <div class="text-right">-->
              <!--                    {{ item.minPriceAll ? $utils.comma(item.minPriceAll) + ' 원' : '-' }}<br/>-->
              <!--                    <small>{{ item.minPriceAllDt || '-'}}</small>-->
              <!--                  </div>-->
              <!--                </b-col>-->
              <!--                <b-col cols="6" xl="4" class="mb-2">-->
              <!--                  <strong>추천가(쿠폰)</strong>-->
              <!--                  <i class="ml-1 fa fa-question-circle" v-b-tooltip="`발란 추천가에 쿠폰할인(${priceMetaMap.global_coupon_rate} %)이 적용된 가격입니다. 시장 최저가(네이버, 다나와)와 비교 가능합니다.`"></i>-->
              <!--                  <br/>-->
              <!--                  <div class="text-right">-->
              <!--                    {{ item.minPriceAllCoupon ? $utils.comma(item.minPriceAllCoupon) + ' 원' : '-' }}<br/>-->
              <!--                    <small>{{ item.minPriceAllDt || '-'}}</small>-->
              <!--                  </div>-->
              <!--                </b-col>-->
              <!--              </b-row>-->
              <b-row>
                <b-col cols="6" xl="4" class="mb-2">
                  <strong style="letter-spacing: -1.7px">수동 최저가</strong><br/>
                  <div class="text-right">
                    {{ item.minPriceManual ? $utils.comma(item.minPriceManual) + ' 원' : '-' }}<br/>
                    <small>{{ item.minPriceManualDt || '-'}}</small>
                  </div>
                </b-col>
                <b-col cols="6" xl="4" class="mb-2">
                  <strong style="letter-spacing: -1.7px">N랭킹 최저가</strong><br/>
                  <div class="text-right">
                    {{ item.minPriceNaverRank ? $utils.comma(item.minPriceNaverRank) + ' 원' : '-' }}<br/>
                    <small>{{ item.minPriceNaverRankDt || '-'}}</small>
                  </div>
                </b-col>
                <b-col cols="6" xl="4" class="mb-2">
                  <strong style="letter-spacing: -1.7px">N랭킹(해외) 최저가</strong><br/>
                  <div class="text-right">
                    {{ item.minPriceNaverAbroadRank ? $utils.comma(item.minPriceNaverAbroadRank) + ' 원' : '-' }}<br/>
                    <small>{{ item.minPriceNaverAbroadRankDt || '-'}}</small>
                  </div>
                </b-col>
                <!--                <b-col cols="6" xl="4" class="mb-2">-->
                <!--                  <strong>N랭킹검수</strong><br/>-->
                <!--                  <div class="text-right">-->
                <!--                    {{ item.rankUrlConfirmedDate || '-' }}<br/>-->
                <!--                    <span v-if="(item.naver_rank_urls || []).concat(item.naver_rank_abroad_urls  || []).filter(e => e).length > 0" class="badge alert-success pointer" @click="setUrlDate('rank')">오늘로 적용</span>-->
                <!--                  </div>-->
                <!--                </b-col>-->
              </b-row>
              <b-row>
                <b-col cols="6" xl="4" class="mb-2">
                  <strong>네이버 최저가</strong><br/>
                  <div class="text-right" :style="item.minPriceAll ===  item.minPriceNaver ? 'color:blue;': ''">
                    {{ item.minPriceNaver ? $utils.comma(item.minPriceNaver) + ' 원' : '-' }}<br/>
                    <small>{{ item.minPriceNaverDt || '-'}}</small>
                  </div>
                </b-col>
                <b-col cols="6" xl="4" class="mb-2">
                  <strong>다나와 최저가</strong><br/>
                  <div class="text-right" :style="item.minPriceAll ===  item.minPriceDanawa ? 'color:blue;': ''">
                    {{ item.minPriceDanawa ? $utils.comma(item.minPriceDanawa) + ' 원' : '-'}}<br/>
                    <small>{{ item.minPriceDanawaDt || '-'}}</small>
                  </div>
                </b-col>
                <!--                <b-col cols="6" xl="4" class="mb-2">-->
                <!--                  <strong>최저가입력검수</strong><br/>-->
                <!--                  <div class="text-right">-->
                <!--                    {{ item.urlConfirmedDate || '-' }}<br/>-->
                <!--                    <span v-if="(item.naver_urls || []).concat(item.danawa_urls || []).concat(item.danawa_list_urls || []).filter(e => e).length"-->
                <!--                          class="badge alert-success pointer" @click="setUrlDate('other')">오늘로 적용</span>-->
                <!--                  </div>-->
                <!--                </b-col>-->
                <b-col cols="6" xl="4" class="mb-2">
                  <strong>네이버API 최저가</strong><br/>
                  <div class="text-right" :style="item.minPriceAll ===  item.minPriceNaverApi ? 'color:blue;': ''">
                    {{ item.minPriceNaverApi ? $utils.comma(item.minPriceNaverApi) + ' 원' : '-'}}<br/>
                    <small>{{ item.minPriceNaverApiDt || '-'}}</small>
                  </div>
                </b-col>
              </b-row>
            </b-tab>
            <!-- 카탈로그 상품 파트 -->
            <b-tab :title="`카탈로그 상품(총 ${item.totalCount} 개 / LIVE ${item.liveCount} 개)`">
              <div class="clearfix mb-2">
                <div class="pull-left">
                  <b-form-radio-group class="options-btn options-btn-dark" v-model="live_status" button-variant="light" size="sm"
                                      :options="[{text: '전체', value: 'ALL'}, {text: '라이브 상품 보기', value: 'Y'}]" buttons></b-form-radio-group>
                </div>
                <div class="pull-right">
                  <b-form inline>
                    <b-form-radio-group class="options-btn options-btn-success" v-model="cfPerLine" button-variant="light" size="sm"
                                        :options="[{text: '2', value: 2}, {text: '3', value: 3}]" buttons></b-form-radio-group>
                    <b-btn-group></b-btn-group>
                    <b-dropdown right size="sm" variant="light" class="ml-2">
                      <template #button-content>
                        <i class="fa fa-copy"></i>
                      </template>
                      <b-dropdown-item @click="copy('goods_no')">발란코드</b-dropdown-item>
                    </b-dropdown>
                  </b-form>
                </div>
              </div>

              <perfect-scrollbar style="max-height: 788px; width: 100%; overflow-y: auto">
                <div class="d-flex flex-row flex-wrap" v-if="(item.cfs || []).length">
                  <div v-for="(e, idx) in item.cfs.filter(e => live_status === 'ALL' || e.isLive).slice(0, 20)"
                       :key="e.goods_no" class="mb-3" :style="{width: cfPerLine === 3 ? 'calc(33% - 10px)' : 'calc(50% - 5px)', marginRight: idx % 3 === 2 ? '0' : '5px'}">
                    <div class="position-relative">
                      <img :src="e.img_urls[0].replace('.jpg', '_300.webp')" style="width: 100%;">
                      <div class="position-absolute text-left" style="top:0;left:0;line-height:15px">
                        <b-badge variant="info" title="B-Rank Class">{{ e.b_rank_class }}</b-badge><br/>
                        <b-badge v-if="e.isLive" variant="success">LIVE</b-badge>
                      </div>
                      <div class="position-absolute text-right" style="top:0;right:0;line-height:15px">
                        <template v-if="e.goods_no">
                          <a :href="`/#/master/matching/${e.goods_no}`" target="_blank" class="badge badge-primary">{{e.goods_no}} <i class="fa fa-external-link"></i></a>
                          <b-badge variant="light" class="pointer" @click="$utils.copyAlert(e.goods_no, {msg: `${e.goods_no} 로 복사되었습니다.`})"><i class="fa fa-copy"></i></b-badge>
                        </template>
                      </div>
                      <div class="position-absolute w-100 text-left" style="bottom:0;left:0;line-height:15px">
                        <b-badge variant="success" class="mw-100 ellipsis" :title="`${e.shop_id}. ${shopMap[e.shop_id].boutique}`">{{e.shop_id}}. {{shopMap[e.shop_id].boutique}}</b-badge>
                      </div>
                    </div>
                    <div style="line-height:18px">
                      <div class="text-truncate overflow-hidden fs-11 bold" :title="e.goods_nm">{{e.goods_nm}}</div>
                      <div class="text-truncate overflow-hidden fs-13 bold mb-1">{{$utils.comma(e.standardPrice)}} 원</div>
                      <div class="text-truncate overflow-hidden fs-13 bold mb-1">{{$utils.comma(e.price)}} 원</div>
                      <div class="text-truncate overflow-hidden fs-13 bold mb-1">{{$utils.comma(e.epPrice)}} 원</div>
                      <div style="display: -webkit-box; -webkit-line-clamp: 5; -webkit-box-orient: vertical; overflow: hidden;">
                        <b-badge class="text-truncate overflow-hidden bold mr-1">{{e.options[0] && e.options[0].optnm || ''}}</b-badge>
                        <b-badge v-for="opt of e.options.filter(e => !e.not_found)" class="text-truncate overflow-hidden bold mr-1"
                                 :variant="opt.stock === 1 ? 'warning' : opt.stock > 1 ? 'success' : 'light'"
                                 :title="`[재고 ${opt.stock}개] ${opt.Size}`" style="max-width:100%;width:fit-content;">{{opt.Size}}</b-badge>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="item.cfs && (live_status === 'ALL' ? item.totalCount : item.liveCount) > 20" class="p-4 text-center">
                  {{(live_status === 'ALL' ? item.totalCount : item.liveCount) - 20}} 개의 상품이 더 존재합니다. <br/>
                  전체 리스트는 <a :href="`/#/master/matching?gm_id=${item.id}`" target="_blank">마스터SKU 매칭</a> 에서 확인해주세요.
                </div>
              </perfect-scrollbar>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>

      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button v-if="item.id && $R(['GOODS_X'])" variant="outline-success" @click="refreshRank()">
          랭킹 재계산
        </b-button>
        <b-button v-if="item.id && $R(['GOODS_X', 'DEV'])" variant="outline-light" @click="jsonModal('JSON 보기', item)">
          JSON
        </b-button>
        <b-button v-if="item.id && $R('SKU_INHOUSE')" variant="outline-light" @click="showDiffModal(_diff)">
          수정이력
        </b-button>
        <b-button v-if="item.id && $R(['GOODS_X', 'DEV'])" variant="outline-danger" @click="terminate()">
          삭제
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          닫기
        </b-button>
        <b-button type="submit" variant="primary" @click="ok()">
          저장
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Sparkline from '@/views/charts/Sparkline.vue'
import ColorRadio from "../modules/ColorRadio";
import {postCatalogApi} from "../../shared/api"

export default {
  name: "MasterModal",
  props: ['modal', 'shopMap', 'designerSkuPatternMap'],
  components: {ColorRadio, Sparkline},
  data() {
    return {
      brand: [],
      brandMap: {},
      category: [],
      categoryMap: {},
      priceMetaMap: {},
      compositionTag: [],
      features: [], // 최종카테고리일 때만 데이터 존재
      colorMap: this.$utils.arr2map(this.$C.COLORS, 'name'),
      catalogTemplate: [],
      catalogTemplateMap: {},
      inputCate: ['', '', '', '', ''],
      cate: [[], [], [], [], []],
      cateNoGender: [{text: '성별을 선택해주세요', value: null, disabled: true},],
      cateNoOpt: [{text: '상위 카테고리를 선택해주세요', value: null, disabled: true},],
      busy: {upload: false, uploadTemp: false, uploadStudio: false, setUrlDate: false},
      drag: {image: false},
      warning: {season: null},
      _cname: '',
      _cdt: '',
      preset: {
        id: '',
        name: '',
        name_en: '',
        img_urls: [],
        images: [],
        temp_img_urls: [],
        temp_images: [],
        studio_images: [],
        studio_img_urls: [],
        naver_urls: [''],
        naver_rank_urls: [''],
        naver_rank_abroad_urls: [''],
        danawa_urls: [''],
        danawa_list_urls: [''],
        brand: null,
        category: '',
        season: '',
        color: '',
        comments: '',
        compoSelect: null,
        composition_tag: '',
        composition: '',
        created_type: 'manual',
        feature1: '',
        feature2: '',
        feature3: '',
        size_table: '',
        description: '',
        designer_sku: '',
        processing_status: 'processing',
        processing_status_reason: '',
        display_status: 'notview',
        display_status_reason: '',
        ep_image: null,
        ep_img_url: '',
        fit_guide: '',
        measurements: '',
        model_bodysize: '',
        modelsize: '',
        care: '',
        src_url: '',
        img_uploader_name: '',
        img_uploaded_dt: '',
        registrant_name: '',
        registered_dt: '',
        displayer_name: '',
        displayed_dt: '',
        template_code: '',
        catalog_template: null,
        seasonCate: 'EMPTY',
        seasonNumber: '',
        minSalePrice14d: '',
        minSalePriceDate: '',
        minPriceNaver: '',
        minPriceNaverDt: '',
        minPriceNaverRank: '',
        minPriceNaverRankDt: '',
        minPriceNaverAbroadRank: '',
        minPriceNaverAbroadRankDt: '',
        minPriceDanawa: '',
        minPriceDanawaDt: '',
        minPriceAll: '',
        minPriceAllDt: '',
        minPriceBalaan: '',
        minPriceBalaanDt: '',
        minPriceNaverApi: '',
        minPriceNaverApiDt: '',
        rankUrlConfirmedDate: '',
        urlConfirmedDate: '',
        rankAbroadUrlConfirmedDate: '',
        naverKeyword: '',
        minPriceManual: '',
        minPriceManualDt: '',
        cfs: [],
        totalCount: 0,
        liveCount: 0,
      },
      item: null,
      items: {fWordsList: []},
      fWords: [],
      ruleReplace: [],
      item_org: null,
      _diff: null,
      lastCategory: '',
      live_status: 'Y',
      cfPerLine: 3,
    };
  },
  async created() {
    this.fWordsList();
    let meta = await this.$api.getMeta('brand,category,price');

    meta.price.forEach(e => {
      this.priceMetaMap[e.price_meta_name] = e.price_meta_value;
    });

    this.brand = meta.brand.map(e=>{
      return this.brandMap[e.brand_no] = {...e, value:e.brand_no, label:`${e.brand_no}. ${e.brand_nm} (${e.brand_nm_kr})`};
    }).sort((a,b) => a.value - b.value);

    this.catalogTemplate = this.$C.CATALOG_TEMPLATE.map(e=>{
      return this.catalogTemplateMap[e.code] = {...e, value:e.code, label:`${e.code}. ${e.name}`};
    }).sort((a,b) => a.value - b.value);

    this.category = meta.category.map(e => {
      return this.categoryMap[e.category] = {...e, value: e.category, text: e.category_nm};
    }).sort((a, b) => (a.value.length - b.value.length) * 10 + a.value.localeCompare(b.value))
      .filter(e => !e.category.match(/^...00(1|7)/) && !e.category.match(/^...(008|111)/));
    meta.category.forEach(e => {
      this.categoryMap[e.category].path = Array(e.category.length / 3).fill(0)
        .map((n, i) => this.categoryMap[e.category.substring(0, i * 3 + 3)].category_nm).join(' > ');
    });

    const ct = await this.$api.getJson('/meta/master/compositionTag');
    if (ct) {
      this.compositionTag = ct.options;
    }
  },
  watch: {
    inputCate: {
      deep: true,
      handler() {
        this.lastCategory = this.inputCate.filter(e => e).slice(-1)[0];
      }
    },
    compoSelect(v) {
      if (typeof v === 'object') { // 선택되었다면
        this.item.composition_tag = v.name;
      }
    }
  },
  computed: {
    designerSkuSamples: function () {
      const brandno = this.item && this.item.brand ? this.item.brand.brand_no : -1;
      const patterns = (this.designerSkuPatternMap[brandno] || {patterns: []}).patterns;
      return patterns.map(e => e.sample);
    },
  },
  methods: {
    async showModal(item) {
      let newItem = {};
      for (const k of Object.keys(this.preset)) {
        newItem[k] = item && item[k] || this.preset[k];
      }

      if (item) {
        newItem.brand = this.brandMap[item.brand_no];
        newItem.catalog_template = this.catalogTemplateMap[item.template_code];

        // newItem.category = this.categoryMap[item.category];
        this.selectCategory(item.category);

        this._cdt = item._cdt;
        this._cname = item._cname;
        this._diff = item._diff;

        let seasonNumber = '';
        let seasonCate = '';
        if (item.season) {
          [seasonNumber] = item.season.match(/\d{2}/g) || [];
          [seasonCate] = item.season.match(/\D{2}/g) || [];
        } else {
          seasonCate = 'EMPTY';
        }

        newItem.seasonCate = seasonCate;
        newItem.seasonNumber = seasonNumber;

        const j = await this.$api.getJson(`/master/catalogList?gm_id=${item.id}`);
        newItem.naverCatalogMap = j && j.ok ? j.naverCatalogMap : {};
        newItem.naverRankCatalogMap = j && j.ok ? j.naverRankCatalogMap : {};
        newItem.danawaCatalogMap = j && j.ok ? j.danawaCatalogMap : {};
        newItem.danawaListMap = j && j.ok ? j.danawaListMap : {};
        if (item.naver_urls && !item.naver_urls.length) {
          newItem.naver_urls = [''];
        }
        if (item.naver_rank_urls && !item.naver_rank_urls.length) {
          newItem.naver_rank_urls = [''];
        }
        if (item.naver_rank_abroad_urls && !item.naver_rank_abroad_urls.length) {
          newItem.naver_rank_abroad_urls = [''];
        }
        if (item.danawa_urls && !item.danawa_urls.length) {
          newItem.danawa_urls = [''];
        }
        if (item.danawa_list_urls && !item.danawa_list_urls.length) {
          newItem.danawa_list_urls = [''];
        }
        await this.list(newItem);
        newItem.minPriceBalaanCoupon = Math.floor(newItem.minPriceBalaan * (100 - this.priceMetaMap.global_coupon_rate) / 1000) * 10;
        newItem.minPriceAllCoupon = Math.floor(newItem.minPriceAll * (100 - this.priceMetaMap.global_coupon_rate) / 1000) * 10;
        newItem.balaanMinPriceClass = !newItem.minPriceAll ? '' : newItem.minPriceBalaan <= newItem.minPriceAll ? 'text-success' : 'text-danger';

        // 소재(composition_tag) 에 맞는 compoSelect 선택
        if (item.composition_tag) {
          newItem.compoSelect = this.compositionTag.map(e => e.compositions).flat().find(e => e.name === item.composition_tag)
        }
      }

      this.item = this.$utils.clone(newItem);
      this.item_org = this.$utils.clone(newItem);

      this.modal.detail = true;
      this.warning.season = null;
    },

    async list(newItem) {
      let j = await this.$api.postJson('/master/detail', {id: newItem.id});
      j.cfs.forEach(e => {
        e.isLive = e.goods_status === 'registered' && e.stock_status === 'normal' && e.display_status === 'view';
      });
      newItem.cfs = j.cfs || [];
      newItem.totalCount = j.totalCount || 0;
      newItem.liveCount = j.liveCount || 0;
    },

    resetModalData() {
      this.inputCate = ['', '', '', '', ''];
      this.cate = [[], [], [], [], []];
      this._cdt = '';
      this._cname = '';
      this._diff = null;
    },

    addOption(field) {
      this.item[field].push('');
    },
    removeOption(field, idx) {
      if (this.item[field].length === 1) {
        this.item[field] = [''];
        return;
      }
      this.item[field].splice(idx, 1);
    },

    async fWordsList() {
      const j = await this.$api.postJson('/meta/forbiddenWord', {});
      if (j.ok) {
        this.items.fWordsList = j.list;
        this.fWords = this.items.fWordsList.filter(e => e.rule === 'remove').map(e => e.word).sort((a, b) => b.length - a.length || a.localeCompare(b));
        this.ruleReplace = this.items.fWordsList.filter(e => e.rule === 'replace');
      } else {
        alert('금칙어를 불러오는데에 실패하였습니다.')
      }
    },
    applyFWords(name) {
      /*
        3안 - 특수기호가 단독으로 제거되면 브랜드명이 훼손된다.
        브랜드명을 임시 보관 후 붙이거나,
        브랜드가 사용하는 특수기호를 제외 후 교체한다.
       */
      const matchedWords = [];
      name = ' ' + name + ' '; // 금칙어 전후로 스페이스가 있어야 한다(단어로 구분이 되어야 한다)

      const fChars = this.fWords.filter(w => w.length === 1 && !w.match(/[a-z0-9ㅏ-ㅣㄱ-ㅎ가-힣]/i)).concat([' /', '/ ']);
      const fWords = this.fWords.filter(w => w.length > 1 || w.match(/[a-z0-9ㅏ-ㅣㄱ-ㅎ가-힣]/i));
      for (const r of this.ruleReplace) {
        if (name.includes(r.word)) {
          matchedWords.push(`${r.word}=>${r.tobe}`);
          name = name.replaceAll(r.word, r.tobe);
        }
      }

      for (const w of fWords) {
        const escWord = w.replace(/[/\\^$*+?.()|[\]{}]/g, '\\$&'); // escape regexp
        const re = new RegExp(`\\s*[^a-z0-9ㅏ-ㅣㄱ-ㅎ가-힣]${escWord}[^a-z0-9ㅏ-ㅣㄱ-ㅎ가-힣]\\s*`, 'gi');
        const matched = name.match(re);
        if (matched) {
          matchedWords.push(w);
          matched.forEach(e => {
            name = name.replace(e, ' ');
          });
        }
      }
      for (const w of fChars) {
        if (name.includes(w)) {
          matchedWords.push(w);
          name = name.replaceAll(w, ' ');
        }
      }
      return {name: name.replace(/\s+/g, ' ').trim(), matchedWords};
    },
    async hideModal(e) {
      this.item.season = this.item.seasonCate !== 'EMPTY' ?  (this.item.seasonNumber.trim() + this.item.seasonCate.trim()) : '';
      const CHECK_COL = ('id,name,name_en,images,temp_images,brand,category,season,color,comments,compoSelect,composition_tag,composition,created_type,' +
          'size_table,description,designer_sku,processing_status,processing_status_reason,display_status,display_status_reason,' +
          'feature1,feature2,feature3,fit_guide,measurements,model_bodysize,src_url,season,studio_images,catalog_template,' +
          'naver_urls,naver_rank_urls,danawa_urls,danawa_list_urls,naver_rank_abroad_urls,naverKeyword,minPriceManual').split(',');
      const modifiedCols = CHECK_COL.filter(e=>JSON.stringify(this.item_org[e]) !== JSON.stringify(this.item[e]));
      if (!modifiedCols.length) {
        this.resetModalData();
        return;
      }

      if (['cancel', 'headerclose', 'esc', 'backdrop'].includes(e.trigger)) {
        // 닫기
        if (!confirm('저장하지 않은 데이터는 사라집니다. 취소하시겠습니까?')) {
          e.preventDefault && e.preventDefault();
          return;
        }
      } else if (e.trigger === 'ok') {  // 마스터 저장
        const title = this.item.id ? '마스터 생성 에러' : '마스터 수정 에러';
        // Validation
        // 1. 브랜드
        if (!this.item.brand) {
          this.$modal.show({title, html: '브랜드를 선택해 주세요.'});
          e.preventDefault && e.preventDefault();
          return;
        }

        // 2. 카테고리
        if (!this.item.category || !this.categoryMap[this.item.category].final) {
          this.$modal.show({title, html: '최종 카테고리를 선택해 주세요.'});
          e.preventDefault && e.preventDefault();
          return;
        }

        // 3. 디자이너 SKU
        if (!this.item.designer_sku) {
          this.$modal.show({title, html: '디자이너 SKU를 입력해 주세요.'});
          e.preventDefault && e.preventDefault();
          return;
        }

        // 3-1. 디자이너 SKU 검사;
        const {patterns} = this.designerSkuPatternMap[this.item.brand.brand_no] || {patterns: []};
        if (patterns.length) {
          const A = '[A-Z]';
          const AN = '[A-Z0-9]';
          const NAN = '[^a-zA-Z0-9]';
          const isValidDesignerSku = patterns.some(e => {
            // exact match를 위해 ^$ 추가
            const regex = new RegExp('^' + e.reStr.replace(/\$\{NAN\}/g, NAN).replace(/\$\{AN\}/g, AN).replace(/\$\{A\}/g, A) + '$');
            return regex.test(this.item.designer_sku);
          })
          if (!isValidDesignerSku) {
            this.$modal.show({title, html: '올바른 형식의 디자이너 SKU를 입력해 주세요.'});
            e.preventDefault && e.preventDefault();
            return;
          }
        }

        // 4. 마스터 상품명
        if (!this.item.name) {
          this.$modal.show({title, html: '마스터 상품명 (한글) 을 입력해 주세요.'});
          e.preventDefault && e.preventDefault();
          return;
        }
        if (this.item.name.length > 100) {
          this.$modal.show({title, html: '마스터 상품명 (한글) 은 최대 100자까지 입력이 가능합니다.'});
          e.preventDefault && e.preventDefault();
          return;
        }
        if (this.item.name_en.length > 100) {
          this.$modal.show({title, html: '마스터 상품명 (영문) 은 최대 100자까지 입력이 가능합니다.'});
          e.preventDefault && e.preventDefault();
          return;
        }

        const {name, matchedWords} = this.applyFWords(this.item.name);

        if (matchedWords.length) {
          if (!confirm(`마스터 상품명 (한글) 에 금칙어가 포함되어있습니다. 변경되는 금칙어: ${matchedWords.join(',')}`)) {
            e.preventDefault && e.preventDefault();
            return;
          } else {
            this.item.name = name;
          }
        }
        // 5. 이미지
        if (!this.item.images.length && !this.item.temp_images.length) {
          this.$modal.show({title, html: '최소 한 장의 이미지(임시, 최종 무관)를 업로드해 주세요.'});
          e.preventDefault && e.preventDefault();
          return;
        }

        // EP 이미지 설정
        if (this.item.images.length) {
          const isClothes = /^...002/.test(this.item.category);
          this.item.ep_image = isClothes ? this.item.images[1] : this.item.images[0];
          if (!this.item.ep_image) {
            if (!isClothes) {
              this.$modal.show({title, html: 'EP 이미지가 설정되지 않았습니다. IT팀에 문의해 주세요.'});
              e.preventDefault && e.preventDefault();
              return;
            }
            this.item.ep_image = null;
          }
        } else {
          this.item.ep_image = null;
        }

        // 6. 색상
        if (!this.item.color) {
          this.$modal.show({title, html: '색상을 선택해 주세요.'});
          e.preventDefault && e.preventDefault();
          return;
        }

        // 7. 소스 URL
        if (!this.item.src_url) {
          this.$modal.show({title, html: '소스 URL을 입력해 주세요.'});
          e.preventDefault && e.preventDefault();
          return;
        }

        // 8. 등록상태가 변경되면 사유도 변경해야 한다.
        if (modifiedCols.includes('processing_status')) {
          if (!this.item.processing_status_reason || !modifiedCols.includes('processing_status_reason')) {
            this.$modal.show({title, html: '등록상태를 변경하면 사유도 변경되어야 합니다.'});
            e.preventDefault && e.preventDefault();
            return;
          }
        }

        // 9. 노출상태가 변경되면 사유도 변경해야 한다.
        if (modifiedCols.includes('display_status')) {
          if (!this.item.display_status_reason || !modifiedCols.includes('display_status_reason')) {
            this.$modal.show({title, html: '등록상태를 변경하면 사유도 변경되어야 합니다.'});
            e.preventDefault && e.preventDefault();
            return;
          }
        }

        if (this.item.processing_status === 'registered' && this.item.display_status === 'view' && !this.item.images.length) {
          this.$modal.show({title, html: '검수 후 노출 단계에서는 최종이미지가 있어야 합니다.'});
          e.preventDefault && e.preventDefault();
          return;
        }

        // 10. 브랜드를 변경하면 매칭되어 있는 모든 상품이 매칭 해제된다.
        let brandModified = false;
        if (this.item.id && modifiedCols.includes('brand')) {
          // 닫기
          if (!confirm('브랜드를 변경하면 마스터에 매칭된 모든 상품이 보류 처리됩니다. 계속하시겠습니까?')) {
            e.preventDefault && e.preventDefault();
            return;
          }
          brandModified = true;
        }

        // 11. 시즌
        if (this.item.seasonCate !== 'EMPTY' && !/^\d{2}\D{2}$/.test(this.item.season)) {
          this.$modal.show({title, html: '시즌을 입력해 주세요.'});
          e.preventDefault && e.preventDefault();
          this.warning.season = false;
          return;
        }

        // 12. 사이즈 변환
        if (this.item.size_table.length >= 500) {
          this.$modal.show({title, html: '사이즈 변환 정보는 500자 이내로 입력 부탁드립니다. 현재 500자 초과 작성!'});
          e.preventDefault && e.preventDefault();
          return;
        }

        // 13. 소재, 상세 정보
        if (this.item.compoSelect && typeof this.item.compoSelect === 'object') { // 선택되었다면
          this.item.composition_tag = this.item.compoSelect.name;
        }
        if (this.item.composition.length >= 500 || this.item.description.length >= 500) {
          this.$modal.show({title, html: '소재나 상세 정보는 500자 이내로 입력 부탁드립니다. 현재 500자 초과 작성!'});
          e.preventDefault && e.preventDefault();
          return;
        }

        // 14. 영문명 trim
        this.item.name_en = this.item.name_en.trim();

        // 15. 수동 최저가
        if (this.item.minPriceManual) {
          const minPriceManual = parseInt(this.item.minPriceManual);

          if (isNaN(minPriceManual)) {
            this.$modal.show({title, html: '수동 최저가에 올바른 숫자를 입력해주세요.'});
            e.preventDefault && e.preventDefault();
            return;
          }

          const { minPriceBalaan, minPriceAll } = this.item;

          const minPriceBalaanDelta = minPriceBalaan ? Math.floor((minPriceManual - minPriceBalaan) / minPriceBalaan * 100) : null;
          const minPriceAllDelta = minPriceAll ? Math.floor((minPriceManual - minPriceAll) / minPriceAll * 100) : null;

          if ((minPriceBalaanDelta !== null || minPriceAllDelta !== null) &&
              !confirm([
                '수동최저가를 저장하시겠습니까?',
                ...(minPriceBalaanDelta !== null ? [`- B최저가(${minPriceBalaan}원) 대비 ${minPriceBalaanDelta}% 차이가 납니다.`] : []),
                ...(minPriceAllDelta !== null ? [`- 발란추천가(${minPriceAll}원) 대비 ${minPriceAllDelta}% 차이가 납니다.`] : []),
              ].join('\n'))
          ) {
            e.preventDefault && e.preventDefault();
            return;
          }

          if (minPriceManual <= 10000) {
            this.$modal.show({title, html: '수동 최저가를 10,000원 이하로 입력하였습니다.'});
            e.preventDefault && e.preventDefault();
            return;
          }

          this.item.minPriceManual = minPriceManual;
        }

        // 마스터 저장
        this.warning.season = null;
        const res = await this.$api.postJson('/master/save', {
          ...this.item,
          category_nm: this.categoryMap[this.item.category].category_nm,
          category_path: this.getCategoryPath(this.item.category),
          brandModified,
        });

        if (res) {
          if (this.item.id) {
            this.$alertTop(`마스터 ${this.item.id}가 수정되었습니다.`);
            // 검수 트래킹
            if (modifiedCols.includes('processing_status') && this.item.processing_status === 'registered') {
              this.$api.sendBeacon('/master/track/registerMaster', {
                gm_ids: [this.item.id],
              });
            }
          } else {
            this.$alertTop(`마스터가 생성되었습니다.`);
            // 마스터 생성 트래킹
            this.$api.sendBeacon('/master/track/createMaster', {
              gm_id: res.id,
            });
          }
          // 이미지 등록 트래킹
          if (!this.item_org.images.length && this.item.images.length) {
            this.$api.sendBeacon('/master/track/uploadImages', {
              gm_id: res.id,
              category: this.item.category,
            });
          }
          // 부가정보 입력 트래킹
          const addedInfos = [];
          for (const additionalInfo of ['composition', 'measurements', 'season']) {
            if (!this.item_org[additionalInfo] && this.item[additionalInfo]) addedInfos.push(additionalInfo);
          }
          if (addedInfos.length) {
            this.$api.sendBeacon('/master/track/additionalInfos', {
              gm_id: res.id,
              addedInfos,
            });
          }
          this.$emit('save');
        }
      }
      this.resetModalData();
    },
    async terminate() {
      if (confirm('정말로 이 마스터를 삭제하시겠습니까?')) {
        // let j = await postJson('', {item: {oid: this.item.oid}});
        let j = await this.$api.postJson('/master/setProcessingStatus', {
          form: {
            ids: [this.item.id],
            processing_status: 'terminated',
          }
        });
        if (j) {
          this.inputCate = ['', '', '', '', ''];
          this.cate = [[], [], [], [], []];
          this._cdt = '';
          this._cname = '';
          this._diff = null;
          this.modal.detail = false;
          this.$alertTop('마스터가 삭제되었습니다.', {variants: 'danger'});
          this.$emit('save');
        }
      }
    },

    jsonModal(title, json) {
      this.$modal.show({title, type:'json', item:json});
    },

    showDiffModal(_diff){
      this.$modal.show({title: '수정이력 확인', type: 'diff', item: {_diff}});
    },
    copyText(e) {
      let res = this.$utils.copyToClipboard(e);
      if (res) this.$alertTop(`"${e}" 로 복사되었습니다`);
    },
    getCategoryPath(category) {
      const pathList = [];
      const unit = 3;
      for (let i = 0; i < category.length; i += unit) {
        const subCate = category.substr(0, i + unit);
        pathList.push(this.categoryMap[subCate].category_nm);
      }

      return pathList.join(' > ');
    },
    /**
     * 카테고리 multiselect를 선택할 때 호출된다.
     * 해당 카테고리의 하위 카테고리를 갱신한다.
     *
     * @param {string} category
     */
    selectCategory(category) {
      if (!category) return;
      this.item = {
        ...this.item,
        category,
      };
      let n = category.length / 3;
      // 카테고리 계층 표시
      let names = [];
      for (let i = 0; i < n; i++) {
        let subCate = category.substring(0, (i+1)*3);
        this.cate[i+1] = this.category.filter(e => e.category.startsWith(subCate) && e.category.length === subCate.length + 3)
          .map(e => ({
            text: `${e.category_nm} ${e.final ? '*' : '→'}`,
            value: e.category,
            subname: e.subname
          }));
        names.push(this.categoryMap[subCate].category_nm);
        this.inputCate[i] = subCate;
      }
      // 이후 카테고리는 초기화
      for (let i = n + 1; i < 4; i++) {
        this.cate[i] = [];
      }
      for (let i = n; i < 5; i++) {
        this.inputCate[i] = '';
      }
      this.lastCategory = this.inputCate[n - 1];

      this.getFeatures(category).then();
    },
    /**
     * 카테고리(최종) 에 맞는 feature(특징) 들을 불러온다.
     * @param {string} category
     */
    async getFeatures(category) {
      const j = await this.$api.postJson('/meta/master/getFeatures', {category});
      if (j) {
        this.features = j.list;
      }
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = 'copy';
    },
    async handleTempFile(event) {
      if (event.dataTransfer && event.dataTransfer.effectAllowed === 'move') {
        return;
      }
      if (this.busy.uploadTemp) return this.$modal.show({title: '업로드 에러 확인', html: '이미지 업로드가 이미 진행중입니다. 잠시 후 다시 시도해 주세요.'});
      let files = [...(event.dataTransfer || event.target).files];
      if (!files.length) return this.$modal.show({title: '업로드 에러 확인', html: '파일을 업로드해 주세요.'});

      if (this.item.temp_images.length + files.length > 10) {
        return this.$modal.show({title: '업로드 에러 확인', html: '이미지는 최대 10장까지 업로드할 수 있습니다.'});
      }
      if (files.some(file => !'jpg,jpeg,png,bmp,webp'.split(',').includes(file.name.toLowerCase().split('.').pop()))) {
        return this.$modal.show({title: '업로드 에러 확인', html: 'jpg, jpeg, png, bmp, webp 파일을 업로드해주세요'});
      }

      this.busy.uploadTemp = true;
      const j = await this.$api.postForm('/master/uploadImages', {
        files: (event.dataTransfer || event.target).files
      });
      this.busy.uploadTemp = false;

      if (j) {
        this.item.temp_images = this.item.temp_images.concat(j.imgObjList);
      }
    },
    async handleFile(event) {
      if (event.dataTransfer && event.dataTransfer.effectAllowed === 'move') {
        return;
      }
      if (this.busy.upload) return this.$modal.show({title: '업로드 에러 확인', html: '이미지 업로드가 이미 진행중입니다. 잠시 후 다시 시도해 주세요.'});
      let files = [...(event.dataTransfer || event.target).files];
      if (!files.length) return this.$modal.show({title: '업로드 에러 확인', html: '파일을 업로드해 주세요.'});

      if (this.item.images.length + files.length > 10) {
        return this.$modal.show({title: '업로드 에러 확인', html: '이미지는 최대 10장까지 업로드할 수 있습니다.'});
      }
      if (files.some(file => !'jpg,jpeg,png,bmp,webp'.split(',').includes(file.name.toLowerCase().split('.').pop()))) {
        return this.$modal.show({title: '업로드 에러 확인', html: 'jpg, jpeg, png, bmp, webp 파일을 업로드해주세요'});
      }

      this.busy.upload = true;
      const j = await this.$api.postForm('/master/uploadImages', {
        files: (event.dataTransfer || event.target).files
      });
      this.busy.upload = false;

      if (j) {
        if (j.ok) {
          this.item.images = this.item.images.concat(j.imgObjList);
        } else {
          return this.$modal.show({title: '업로드 에러 확인', html: j.msg});
        }
      }
    },
    async handleStudioFile(event) {
      if (event.dataTransfer && event.dataTransfer.effectAllowed === 'move') {
        return;
      }
      if (this.busy.uploadStudio) return this.$modal.show({title: '업로드 에러 확인', html: '이미지 업로드가 이미 진행중입니다. 잠시 후 다시 시도해 주세요.'});
      let files = [...(event.dataTransfer || event.target).files];
      if (!files.length) return this.$modal.show({title: '업로드 에러 확인', html: '파일을 업로드해 주세요.'});

      if (this.item.studio_images.length + files.length > 15) {
        return this.$modal.show({title: '업로드 에러 확인', html: '이미지는 최대 15장까지 업로드할 수 있습니다.'});
      }
      if (files.some(file => !'jpg,jpeg,png,bmp,webp'.split(',').includes(file.name.toLowerCase().split('.').pop()))) {
        return this.$modal.show({title: '업로드 에러 확인', html: 'jpg, jpeg, png, bmp, webp 파일을 업로드해주세요'});
      }

      this.busy.uploadStudio = true;
      const j = await this.$api.postForm('/master/uploadImages', {
        files: (event.dataTransfer || event.target).files
      });
      this.busy.uploadStudio = false;

      if (j) {
        this.item.studio_images = this.item.studio_images.concat(j.imgObjList);
      }
    },
    copy(col, {withQuotes = false} = {}) {
      const selected = this.item.cfs.filter(e => this.live_status === 'ALL' || e.isLive).slice(0, 20);
      let res = this.$utils.copyToClipboard(selected.map(e => withQuotes ? `'${e[col].toString().replace(/'/g, "\\'")}'` : e[col]).join(withQuotes ? ',\n' : '\n'));
      if (res) this.$alertTop(`${selected.length} 개 복사되었습니다`);
    },

    async setUrlDate(type) {
      if (type === 'rank' && this.item_org.rankUrlConfirmedDate === this.$utils.kstD()) return alert('검수일자가 이미 오늘입니다.');
      if (type === 'other' && this.item_org.urlConfirmedDate === this.$utils.kstD()) return alert('검수일자가 이미 오늘입니다.');

      if (!confirm('검수일자를 오늘로 적용하시겠습니까?')) return;

      this.busy.setUrlDate = true;
      const j = await this.$api.postJson('/master/setUrlDate', {id: this.item.id, type});
      this.busy.setUrlDate = false;
      if (j) {
        if (type === 'rank') {
          this.item_org.rankUrlConfirmedDate = this.item.rankUrlConfirmedDate = this.$utils.kstD();
          this.$emit('updateField', this.item.id, 'rankUrlConfirmedDate', this.item.rankUrlConfirmedDate);
        } else if (type === 'other') {
          this.item_org.urlConfirmedDate = this.item.urlConfirmedDate = this.$utils.kstD();
          this.$emit('updateField', this.item.id, 'urlConfirmedDate', this.item.urlConfirmedDate);
        }
        this.$forceUpdate();
      }
    },
    async refreshRank() {
      let j = await this.$api.postJson('/master/refreshRank', {id: this.item.id});
      if (j) {
        this.$utils.alert('카탈로그 랭킹 재계산이 시작되었습니다. 순차적으로 처리됩니다.');
      }
      // try {
      //   const res = await postCatalogApi(this.CATALOG_HOST +'/catalog/calcCatalogRankRequest', {gmIds: [this.item.id]})
      //
      //   if (res && res.ok) {
      //     this.$utils.alert('카탈로그 랭킹 재계산이 시작되었습니다. 순차적으로 처리됩니다.');
      //   } else {
      //     this.$utils.alert('카탈로그 랭킹 재계산 요청이 실패하였습니다.');
      //   }
      // } catch (e) {
      //   this.$utils.alert(e.message);
      // }
    }
  }
}
</script>
