<template>
  <b-dropdown :size="size" variant="light">
    <template #button-content>
      <template v-if="!colorMap[color]">
        선택해주세요
      </template>
      <template v-else>
        <div v-if="color" class="d-inline-block"
             :style="{background: color === 'multi' ? 'linear-gradient(135deg, red,orange,yellow,green,blue,indigo,violet)' : color,
                              marginBottom: '-4px', width:'18px', height:'18px', border: '1px solid #ccc'}"
             v-b-tooltip="color"></div>
        <div class="d-inline-block ml-1">{{colorMap[color].name_ko}}</div>
      </template>
    </template>
    <b-dropdown-form style="width: 170px">
      <b-form-radio-group class="py-2" id="color" name="color" v-model="color">
        <b-form-radio v-for="c in $C.COLORS" :key="c.name" :value="c.name">
          <div class="d-inline-block"
               :style="{background: c.name === 'multi' ? 'linear-gradient(135deg, red,orange,yellow,green,blue,indigo,violet)' : '#' + c.color,
                                    width:'20px', height:'20px', border: '1px solid #ccc'}"
               v-b-tooltip="c.name_ko"></div>
          <div class="d-inline-block va-inherit ml-1">{{ c.name_ko }}</div>
          <!--                  <span class="d-inline-block border-eee" :style="{backgroundColor: '#'+c.color, width:'14px', height:'14px'}"></span>
                            {{c.name_ko}}-->
        </b-form-radio>
      </b-form-radio-group>
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
export default {
  name: 'ColorCheckboxDropdown',
  model: {prop: 'value', event: 'change'},
  props: {
    value: String,
    size: String,
  },
  data() {
    return {
      colorMap: this.$utils.arr2map(this.$C.COLORS, 'name'),
    };
  },
  computed: {
    color: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
  }
}
</script>
